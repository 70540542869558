import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {blogService} from "../../services/blogService";
import moment from "moment";

function HandBookDetails() {
    const {url} = useParams();
    const [blog, setBlog] = useState({});


    useEffect(() => {
        const getProduct = async () => {
            try {
                const res = await blogService.getBlogById(url);
                setBlog(res.data);
            } catch (e) {
                console.log(e);
            }
        }

        getProduct().then();
    }, [url])

    return (
        <>
            <Header/>
            <section className="container mx-auto py-8">
                <h1 className="text-[#85BDC2] text-7xl font-bold leading-tight mx-10 mb-10">{blog?.title}</h1>
                <div className="grid grid-cols-12">
                    <div className="col-span-9 mx-10">
                        {/* <span
                            className="text-base mt-4">Ngày đăng: {moment(blog?.createdAt).format("DD/MM/yyyy")}</span> */}
                        <div dangerouslySetInnerHTML={{__html: blog?.content}}></div>
                        <p
                            className="text-base mt-4">Tác giả: {blog?.staffName}</p>
                        <p
                            className="text-base mt-4">Cập nhật lần cuối: {moment(blog?.updatedAt).format("DD/MM/yyyy")}</p>

                    </div>
                    <div className="col-span-3">
                        <div className="flex flex-col gap-8 mt-44">
                        <div className="flex flex-col">
                                <h2 className="text-center text-2xl bg-[#85BDC2] p-3.5 font-medium">Thể loại</h2>
                            </div>

                            <div className="flex flex-col">
                                <h2 className="text-2xl bg-[#85BDC2] p-3.5 font-medium">Bài viết liên quan</h2>

                                <ul className="max-w-md divide-y-4 divide-white bg-[#DCF7F9]">
                                    <li className="p-4">
                                        <a href="/" className="uppercase font-thin">
                                            ĐIỂM DANH 5 BỆNH LÝ Ở TRẺ THƯỜNG HAY GẶP
                                        </a>
                                    </li>
                                    <li className="p-4">
                                        <a href="/" className="uppercase font-thin">5 OUTFIT MÙA THU “CHẤT” KHỎIBÀN !!</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default HandBookDetails;