import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { authService } from "../../services/authService";
import { useState } from "react";

function Register() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    hasError: false,
    message: "",
  });
  const [type, setType] = useState("password");

  const [form, setForm] = useState({
    email: "",
    password: "",
    fullName: "",
    address: "",
    phoneNumber: "",
  });

  const showPass = () => {
    if (type === "password") {
      setType("text");
      setShow(true);
    } else {
      setType("password");
      setShow(false);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "gender") {
      value = parseInt(value);
    }

    if (e.target.name === "matchingPassword") {
      e.target.onpaste = (e) => e.preventDefault();
    }

    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      email: form.email,
      fullName: form.fullName,
      password: form.password,
      address: form.address,
      phoneNumber: form.phoneNumber,
    };

    setLoading(true);
    const register = async () => {
      try {
        await authService.registration(formData);
      } catch (error) {
        setError({ ...error, hasError: true, message: error.message[0] });
        setLoading(false);
      }
    };
    register().then();
  };

  return (
    <>
      <Header />
      <section className="container mx-auto my-16">
        <div className="flex flex-col w-fit items-center mx-auto">
          <h1 className="text-3xl font-bold text-[#FF884A]">Đăng ký</h1>
          {error.hasError && (
            <div
              className="w-full py-3 mt-4 text-sm text-center text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              The login details are incorrect
            </div>
          )}
          <form onSubmit={handleSubmit} className="mt-8 w-[487px]">
            <div className="mb-6">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Họ và tên của bạn
              </label>
              <input
                type="text"
                id="name"
                name="fullName"
                value={form.fullName}
                onChange={handleChange}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-300 block w-full p-2.5"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-300 block w-full p-2.5"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Số điện thoại
              </label>
              <input
                type="tel"
                id="phone"
                name="phoneNumber"
                value={form.phoneNumber}
                onChange={handleChange}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-300 block w-full p-2.5"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Mật khẩu
              </label>
              <div className="flex border border-gray-300 rounded-lg">
                <input
                  type={type}
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  className="text-gray-900 text-sm rounded-lg focus:ring-0 border-0 block w-full p-2.5"
                  required
                />
                <button type="button" onClick={showPass} className="px-2.5">
                  {!show && <FontAwesomeIcon icon="fa-regular fa-eye-slash" />}
                  {show && <FontAwesomeIcon icon="fa-regular fa-eye" />}
                </button>
              </div>
            </div>
            <button
              disabled={loading}
              className="w-full px-5 py-3 rounded-lg bg-[#85BDC2] text-white text-lg font-bold hover:opacity-85 disabled:bg-gray-300"
            >
              Đăng ký
            </button>
            <div className="mt-4 text-center">
              Đã có tài khoản?
              <Link to="/auth/login" className="text-[#FF884A] hover:underline">
                {" "}
                Đăng nhập
              </Link>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Register;
