import {createSlice} from '@reduxjs/toolkit'
// import { clear } from '../../node_modules/@testing-library/user-event/dist/clear';
// import { createAsyncThunk } from '../../node_modules/@reduxjs/toolkit/dist/index';

const initialState = {
    user: undefined,
    status: 'idle',
    error: null,
}



export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.user = action.payload;
        },
        clearCurrentUser: (state) => {
            state.user = undefined;
        }
    },
    
})

export const {
    setCurrentUser, clearCurrentUser
} = userSlice.actions

export default userSlice.reducer;
