import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import banner3 from "../../assets/banner/banner3.png";
import img21 from "../../assets/images/img21.png";
import img22 from "../../assets/images/img22.jpg";
import img23 from "../../assets/images/img23.jpg";
import { Link } from "../../../node_modules/react-router-dom/dist/index";

function AIChoice() {
    return (
        <>
            <Header/>
            <img src={banner3} alt=""/>
            <div className="bg-[#F5F5F5]">
                <div className="container mx-auto py-10">
                    <h1 className="text-4xl text-[#FF884A] font-black">AI CHOICE LÀ GÌ?</h1>
                    <p className="my-7 text-lg"><strong>AI CHOICE</strong> là ứng dụng thử đồ sử dụng trí thông minh
                        nhân tạo được phát triển bởi đội ngũ <strong>HICON - Newborn & Babycare.</strong>
                        Với dữ liệu phân tích hơn 6000 hình ảnh của trẻ em, <strong>AI CHOICE</strong> có thể giúp
                        con bạn thử đồ trực tuyến mà không mất quá nhiều thời gian.
                    </p>
                    <p className="italic text-lg">Điền ngay thông tin dưới đây để trải nghiệm tính năng mới này
                        nhé! </p>
                </div>
            </div>
            <section className="container mx-auto my-16">
                <div className="flex flex-col w-fit items-center mx-auto">
                    <h1 className="text-3xl font-bold text-[#FF884A]">Bạn hãy nhập tin chính xác thông tin của bé
                        nhé!</h1>
                    <form className="mt-8 w-[487px]">
                        <div className="mb-6">
                            <label htmlFor="first_name"
                                   className="block mb-2 text-sm font-medium text-gray-900">Họ tên
                                bé</label>
                            <input type="text" id="first_name"
                                   className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                   required/>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="phone"
                                   className="block mb-2 text-sm font-medium text-gray-900">Số điện thoại Mẹ</label>
                            <input type="tel" id="phone" pattern="[0-9]{10}"
                                   className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                   required/>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="height"
                                   className="block mb-2 text-sm font-medium text-gray-900">Chiều cao</label>
                            <input type="number" id="height"
                                   className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                   required/>
                        </div>
                        <div className="mb-10">
                            <label htmlFor="weight"
                                   className="block mb-2 text-sm font-medium text-gray-900">Cân nặng</label>
                            <input type="number" id="weight"
                                   className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                   required/>
                        </div>
                        <Link to="/ai-choice-implement">
                            <button
                                className="w-full px-5 py-3 rounded-lg bg-[#FF884A] text-white text-lg font-bold hover:opacity-85">KHÁM
                                PHÁ NGAY
                            </button>
                        </Link>
                    </form>
                </div>

                <div className="flex flex-col gap-4 mt-20">
                    <h1 className="text-[#85BDC2] text-5xl font-light">BẠN CÓ BIẾT</h1>
                    <h2 className="text-[#85BDC2] text-3xl font-bold">CHẤT LIỆU VẢI ẢNH HƯỞNG THẾ NÀO ĐẾN LÀN DA
                        BÉ?</h2>
                </div>
            </section>
            <div className="bg-[#F5F5F5]">
                <div className="container mx-auto">
                    <div className="grid grid-cols-3 gap-11 py-10">
                        <div className="flex flex-col gap-6 rounded-lg">
                            <img src={img21} className="rounded-2xl shadow aspect-[16/10] object-cover" alt=""/>
                            <h3 className="text-2xl font-black px-4">5 chất liệu vàng khi chọn quần áo cho bé yêu</h3>
                            <button
                                className="w-fit px-3 py-1 rounded-lg bg-[#FF884A] text-white text-base font-bold hover:opacity-85">TÌM
                                HIỂU THÊM
                            </button>
                        </div>
                        <div className="flex flex-col gap-6 rounded-lg">
                            <img src={img22} className="rounded-2xl shadow aspect-[16/10] object-cover" alt=""/>
                            <h3 className="text-2xl font-black px-4">Điểm danh 5 outfit mùa đông cho bé con nhà
                                bạn!</h3>
                            <button
                                className="w-fit px-3 py-1 rounded-lg bg-[#FF884A] text-white text-base font-bold hover:opacity-85">TÌM
                                HIỂU THÊM
                            </button>
                        </div>
                        <div className="flex flex-col gap-6 rounded-lg">
                            <img src={img23} className="rounded-2xl shadow aspect-[16/10] object-cover" alt=""/>
                            <h3 className="text-2xl font-black px-4">Thời tiết thay đổi, chất liệu vải cũng nên thay?</h3>
                            <button
                                className="w-fit px-3 py-1 rounded-lg bg-[#FF884A] text-white text-base font-bold hover:opacity-85">TÌM
                                HIỂU THÊM
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default AIChoice;