import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    show: false
};

export const modal = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showHideModal: (state, action) => {
            state.show = action.payload;
        }
    }
});

export const {
    showHideModal
} = modal.actions;

export default modal.reducer;