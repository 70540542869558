import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    cartList: []
};

export const cartSlide = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const idx = state.cartList.findIndex(item => item.id === action.payload);
            if (idx !== -1) {
                state.cartList[idx].count += 1;
            } else {
                state.cartList = [...state.cartList, action.payload];
            }
        },
        increaseCart: (state, action) => {
            const idx = state.cartList.findIndex(item => item.id === action.payload);
            state.cartList[idx].count += 1;
        },
        decreaseCart: (state, action) => {
            const idx = state.cartList.findIndex(item => item.id === action.payload);
            state.cartList[idx].count -= 1;
        },
        removeFromCart: (state, action) => {
            state.cartList = state.cartList.filter(item => item.id !== action.payload);
        }
    }
});

export const {
    addToCart,
    increaseCart,
    decreaseCart,
    removeFromCart
} = cartSlide.actions;

export default cartSlide.reducer;