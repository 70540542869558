import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import banner2 from "../../assets/banner/banner2.png";

function Contact() {
    return (
        <>
            <Header/>
            <section className="container mx-auto mt-10">
                <img src={banner2} className="rounded-lg object-cover aspect-[21/9]" alt=""/>
                <div className="flex justify-between mt-8">
                    <div className="flex flex-col gap-8 mt-4 max-w-[362px]">
                        <h1 className="text-[#85BDC2] text-5xl font-bold uppercase">LIÊN HỆ</h1>
                        <p className="italic">Với mục tiêu tạo ra những trải nghiệm mua sắm trực tuyến tuyệt vời,
                            Hicon luôn nỗ lực không ngừng nhằm nâng cao chất lượng dịch vụ Quý khách có nhu cầu liên
                            lạc, trao đổi hoặc đóng góp ý kiến, vui lòng tham khảo các thông tin sau:</p>
                        <p className="font-medium">Email: Hiconofficial@gmail.com <br/>
                            Hotline: 0866.769.546 <br/>
                            Giờ mở cửa: Từ 8:00 - 22:00 tất cả các ngày trong tuần (trừ ngày lễ, ngày Tết).</p>
                    </div>
                    <div className="flex flex-col gap-6 mt-4">
                        <h1 className="text-[#85BDC2] text-5xl font-bold uppercase mb-2">GỬI PHẢN HỒI CỦA BẠN</h1>
                        <input type="text"
                               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="Họ và tên của bạn" required/>
                        <input type="text"
                               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="Địa chỉ email" required/>
                        <input type="text"
                               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                               placeholder="Số điện thoại" required/>
                        <textarea id="message" rows="8"
                                  className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                  placeholder="Nội dung phản hồi..."></textarea>
                        <div className="flex justify-center mt-4">
                            <button className="px-5 py-2 rounded-lg bg-[#85BDC2] text-white hover:opacity-85">Gửi
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Contact;