import axios from "axios";

export const addressService = {
    listProvinces() {
        return axios.get('https://provinces.open-api.vn/api/p/');
    },
    getProvince(id) {
        return axios.get(`https://provinces.open-api.vn/api/p/${id}`, {params: {depth: 2}});
    },
    getDistrict(id) {
        return axios.get(`https://provinces.open-api.vn/api/d/${id}`, {params: {depth: 2}});
    }
}