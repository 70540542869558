// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prod-new-layout:first-child {
  grid-row: 1/3;
  grid-column: 1/8;
}
.prod-new-layout:nth-child(2) {
  grid-row: 1;
  grid-column: 8/13;
}
.prod-new-layout:nth-child(3) {
  grid-row: 2;
  grid-column: 8/13;
}

.prod-month-suggest:first-child {
  grid-row: 1/3;
  grid-column: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,gBAAA;AAAJ;AAGE;EACE,WAAA;EACA,iBAAA;AADJ;AAIE;EACE,WAAA;EACA,iBAAA;AAFJ;;AAOE;EACE,aAAA;EACA,cAAA;AAJJ","sourcesContent":[".prod-new-layout {\n  &:first-child {\n    grid-row: 1 / 3;\n    grid-column: 1 / 8;\n  }\n\n  &:nth-child(2) {\n    grid-row: 1;\n    grid-column: 8 / 13;\n  }\n\n  &:nth-child(3) {\n    grid-row: 2;\n    grid-column: 8 / 13;\n  }\n}\n\n.prod-month-suggest {\n  &:first-child {\n    grid-row: 1 / 3;\n    grid-column: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
