import axiosClient from "../utils/interceptor";

export const productService = {
    getAllProducts: () => {
        return axiosClient.get("/api/Product");
    },

    getNumberProducts: (number) => {
        return axiosClient.get(`/api/Product/number/${number}`);
    },

    getProductById: (id) => {
        return axiosClient.get("/api/Product/" + id);
    }
}