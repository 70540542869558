import "./home.scss";
import Header from "../../components/header/header";
import banner from 'assets/banner/banner.png';
import img1 from 'assets/images/img1.png';
import img2 from 'assets/images/img2.png';
import img3 from 'assets/images/img3.png';
import img4 from 'assets/images/img4.jpg';
import img5 from 'assets/images/img5.jpg';
import img6 from 'assets/images/img6.jpg';
import img7 from 'assets/images/img7.jpg';
import img8 from 'assets/images/img8.jpg';
import Footer from "../../components/footer/footer";

function Home() {
    return (
        <>
            <Header/>
            <div className="container mx-auto">
                <section>
                    <img src={banner} className="m-7 rounded-2xl" alt=""/>
                    <h1 className="text-6xl text-[#FF884A] font-black my-16 text-center">Sản phẩm mới về</h1>
                    <div className="grid grid-cols-12 grid-rows-2 gap-x-6 gap-y-8">
                        <div className="prod-new-layout">
                            <div className="relative flex flex-col gap-6 rounded-lg">
                                <img src={img1} className="rounded-lg aspect-[10/9] object-cover" alt=""/>
                                <div className="flex flex-col items-center gap-8">
                                    <a href="/san-pham/details" className="text-xl font-black text-center truncate">Bộ
                                        cài thẳng dài áo trắng họa tiết gấu xanh Hicon
                                        Việt Nam chính hãng</a>
                                    <span className="text-xl font-black text-[#FF884A]">200.000 VND</span>
                                </div>
                                <button
                                    className="absolute bottom-0 right-0 focus:ring-4 rounded focus:outline-none focus:ring-[#dcf7f9]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="prod-new-layout">
                            <div className="relative flex flex-col gap-6 rounded-lg">
                                <img src={img2} className="rounded-lg aspect-[18/9] object-cover" alt=""/>
                                <div className="flex flex-col items-center gap-8">
                                    <a href="/san-pham/details" className="text-xl font-black text-center truncate">Áo
                                        rời sơ sinh cộc tay</a>
                                    <span className="text-xl font-black text-[#FF884A]">200.000 VND</span>
                                </div>
                                <button
                                    className="absolute bottom-0 right-0 focus:ring-4 rounded focus:outline-none focus:ring-[#dcf7f9]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="prod-new-layout">
                            <div className="relative flex flex-col gap-6 rounded-lg">
                                <img src={img3} className="rounded-lg aspect-[18/9] object-cover" alt=""/>
                                <div className="flex flex-col items-center gap-8">
                                    <a href="/san-pham/details" className="text-xl font-black text-center truncate">Combo
                                        quần cho bé</a>
                                    <span className="text-xl font-black text-[#FF884A]">200.000 VND</span>
                                </div>
                                <button
                                    className="absolute bottom-0 right-0 focus:ring-4 rounded focus:outline-none focus:ring-[#dcf7f9]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt-28">
                <h1 className="text-6xl text-[#FF884A] font-black my-16 text-center">Tuần này, bé yêu mặc gì?</h1>
                    <div className="grid grid-cols-3 grid-rows-2 gap-6">
                        <div className="prod-month-suggest">
                            <img src={img4} className="rounded-lg h-full object-cover" alt=""/>
                        </div>
                        <div className="prod-month-suggest aspect-square">
                            <img src={img5} className="rounded-lg w-full h-full object-cover" alt=""/>
                        </div>
                        <div className="prod-month-suggest aspect-square">
                        <img src={img6} className="rounded-lg w-full h-full object-cover" alt=""/>
                        </div>
                        <div className="prod-month-suggest aspect-square">
                            <img src={img7} className="rounded-lg w-full h-full object-cover" alt=""/>
                        </div>
                        <div className="prod-month-suggest aspect-square">
                            <img src={img8} className="rounded-lg w-full h-full object-cover" alt=""/>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </>
    )
}

export default Home;