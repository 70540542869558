import axiosClient from "../utils/interceptor";

export const aiService = {
    getAllUppers: () => {
        return axiosClient.get('/api/TryOn/clothes/upper');
    },

    getAllLowers: () => {
        return axiosClient.get('/api/TryOn/clothes/lower');
    },

    getAllModels: () => {
        return axiosClient.get('/api/TryOn/models');
    },

    getTryOnResult: (data) => {
        return axiosClient.post('/api/TryOn', data);
    }
}