import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {useEffect, useState} from "react";
import { aiService } from "services/aiService";
import { Carousel } from 'react-responsive-carousel';
// import {Image} from "next/image";
import Modal from 'react-modal';

Modal.setAppElement('#root');

function AIChoiceImplement() {

    const [currentModel, setCurrentModel] = useState(null);
    const [models, setModels] = useState([]);
    const [currentUpper, setCurrentUpper] = useState(null);
    const [uppers, setUppers] = useState([]);
    const [currentLower, setCurrentLower] = useState(null);
    const [lowers, setLowers] = useState([]);
    const [isModelModalOpen, setIsModelModalOpen] = useState(false);
    const [isUpperModalOpen, setIsUpperModalOpen] = useState(false);
    const [isLowerModalOpen, setIsLowerModalOpen] = useState(false);
    const [tryOnResult, setTryOnResult] = useState(null);

    useEffect(() => {
        const getAllModels = async () => {
            try {
                const res = await aiService.getAllModels();
                setModels(res.data);
                setCurrentModel(res.data[0]);
                console.log(res.data);
            } catch (e) {
                console.log(e);
            }
        }
        // getAllModels().then();

        const getAllUppers = async () => {
            try {
                const res = await aiService.getAllUppers();
                setUppers(res.data);
                setCurrentUpper(res.data[0]);
                console.log(res.data);
            } catch (e) {
                console.log(e);
            }
        }
        // getAllUppers().then();

        const getAllLowers = async () => {
            try {
                const res = await aiService.getAllLowers();
                setLowers(res.data);
                setCurrentLower(res.data[0]);
                console.log(res.data);
            } catch (e) {
                console.log(e);
            }
        }
        getAllModels().then();
        getAllUppers().then();
        getAllLowers().then();
    }, []);

    const openModelModal = () => setIsModelModalOpen(true);
    const openUpperModal = () => setIsUpperModalOpen(true);
    const openLowerModal = () => setIsLowerModalOpen(true);

    const closeModal = () => {
        setIsModelModalOpen(false);
        setIsUpperModalOpen(false);
        setIsLowerModalOpen(false);
    };

    const handleTryOn = async () => {
        if (!currentModel || !currentUpper || !currentLower) {
            alert("Please select a model, upper, and lower clothing.");
            return;
        }
    
        const tryOnData = {
            modelId: currentModel.fileName,
            upperClothId: currentUpper.fileName,
            lowerClothId: currentLower.fileName
        };

        console.log(tryOnData);
    
        try {
            const response = await aiService.getTryOnResult(tryOnData);

            console.log(response.data); // Handle success
            setTryOnResult(response.data);
            // Perhaps display the result image in a modal or on the page
        } catch (error) {
            console.error("Error trying on clothes:", error);
        }
    };

    return (
        <>
            <Header />
            <div className="container mx-auto py-20">
                <div className="grid grid-cols-3 gap-10">
                    {/* Model Image */}
                    <div className="flex flex-col gap-6 rounded-lg px-5" onClick={openModelModal}>
                        <img loading="lazy" src={currentModel?.path} className="rounded-2xl aspect-[1/2] object-cover border border-black" alt=""/>
                        {/* <div className="flex flex-row">
                            {models.map((model, index) => (
                                <div key={index} onClick={() => setCurrentModel(model)}>
                                    <img loading="lazy" src={model.path} className="rounded-lg aspect-[1/1] object-cover" alt=""/>
                                </div>
                            ))}
                        </div> */}
                    </div>

                    {/* Upper and Lower Image */}
                    <div className="flex flex-col gap-6 rounded-lg px-5">
                        <div className="font-black text-xl">
                            Chọn áo cho bé
                        </div>
                        <img loading="lazy" src={currentUpper?.path} className="rounded-3xl aspect-[3/2] my-5 border border-black" alt="" onClick={openUpperModal}/>
                        
                        <div className="font-black text-xl">
                            Chọn quần cho bé
                        </div>
                        <img loading="lazy" src={currentLower?.path} className="rounded-3xl aspect-[3/2] my-5 border border-black" onClick={openLowerModal} alt="" />
                    
                    </div>
                    <div className="flex flex-col gap-6 rounded-lg px-5">
                        <img loading="lazy" src={tryOnResult?.image} className="rounded-3xl aspect-[3/5] object-cover border border-black" alt=""/>
                        <button 
                        className="bg-[#FF884A] text-black py-2 px-4 rounded-2xl font-black text-2xl border border-black" 
                        onClick={handleTryOn}>
                            Thử đồ
                        </button>
                    </div>
                </div>

                {/* Model Modal */}
                <Modal
                    isOpen={isModelModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Change Model"
                    className="Modal"
                    overlayClassName="Overlay"
                    appElement={document.getElementById('root')} // This can be set dynamically
                >
                    <div className="font-bold text-xl">Người mẫu AI</div>
                    <Carousel showThumbs={false} showIndicators={false} infiniteLoop useKeyboardArrows>
                        {models.filter(model => model.fileName === "1" || model.fileName==="9").map((model, index) => (
                            <div key={index} onClick={() => { setCurrentModel(model); closeModal(); }}>
                                <img
                                    src={model.path}
                                    className="cursor-pointer rounded-xl w-36 aspect-[1/2] object-cover py-3"
                                    alt={`Model ${index}`}
                                    
                                />
                            </div>
                        ))}
                    </Carousel>
                </Modal>

                {/* Upper Modal */}
                <Modal
                    isOpen={isUpperModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Change Upper"
                    className="Modal"
                    overlayClassName="Overlay"
                    appElement={document.getElementById('root')} // This can be set dynamically
                >
                    <div className="font-bold text-xl">Chọn áo cho bé</div>
                    <Carousel showThumbs={false} showIndicators={false} infiniteLoop useKeyboardArrows>
                        {uppers.map((upper, index) => (
                            <div key={index} onClick={() => { setCurrentUpper(upper); closeModal(); }}>
                                <img
                                    src={upper.path}
                                    className="cursor-pointer rounded-xl w-36 aspect-[1/2] object-cover py-3"
                                    alt={`Upper ${index}`}
                                />
                            </div>
                        ))}
                    </Carousel>
                </Modal>

                {/* Lower Modal */}
                <Modal
                    isOpen={isLowerModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Change Lower"
                    className="Modal"
                    overlayClassName="Overlay"
                    appElement={document.getElementById('root')} // This can be set dynamically
                >
                    <div className="font-bold text-xl">Chọn quần cho bé</div>
                    <Carousel showThumbs={false} showIndicators={false} infiniteLoop useKeyboardArrows>
                        {lowers.map((lower, index) => (
                            <div key={index} onClick={() => { setCurrentLower(lower); closeModal(); }}>
                                <img
                                    src={lower.path}
                                    className="cursor-pointer rounded-xl w-36 aspect-[1/2] object-cover py-3"
                                    alt={`Lower ${index}`}
                                />
                            </div>
                        ))}
                    </Carousel>

                </Modal>

            </div>
            <Footer />
        </>
    );
}

export default AIChoiceImplement;