import axios from 'axios';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

// Add a request interceptor
axiosClient.interceptors.request.use(
    (config) => {
        const token = getToken();
        // config.headers = {
        //     'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        //     'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Credentials': 'true'
        // };

        if (token != null) {
            config.headers.Authorization = token;
        }

        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
            // eslint-disable-next-line no-throw-literal
            throw { message: ['Please login to continue'] };
        }
        if (error && error.response && error.response.data) {
            throw error.response.data;
        }
        throw error;
    }
);

function getToken() {
    const itemStr = localStorage.getItem('auth-token');
    if (!itemStr) {
        return null;
    } else {
        const item = JSON.parse(itemStr);
        return item.tokenType + ' ' + item.accessToken;
    }
}

export default axiosClient;
