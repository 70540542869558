import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {Link} from "react-router-dom";
import {useState} from "react";
import {authService} from "../../services/authService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch} from "react-redux";
// import { useSelector } from "react-redux";

import {setCurrentUser} from "../../stores/user";

function Login() {
    const [show, setShow] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('password');
    const [form, setForm] = useState({
        username: '',
        password: '',
    });
    const dispatch = useDispatch();
    // const store = require("../../stores").default;

    const handleChange = (e) => {
        const value = e.target.value;
        setForm({
            ...form,
            [e.target.name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);
        const login = async () => {
            try {
                const res = await authService.login(form);  
                dispatch(setCurrentUser(res.data));
                setLoading(false);

                window.location.href = '/';

            } catch (error) {
                setHasError(true);
                setLoading(false);
            }
        };
        login();
    };

    const showPass = () => {
        if (type === 'password') {
            setType('text');
            setShow(true);
        } else {
            setType('password');
            setShow(false);
        }
    }

    return (
        <>
            <Header/>
            <section className="container mx-auto my-16">
                <div className="flex flex-col w-fit items-center mx-auto">
                    <h1 className="text-3xl font-bold text-[#FF884A]">Đăng nhập</h1>
                    {hasError &&
                        <div className="w-full py-3 mt-4 text-sm text-center text-red-800 rounded-lg bg-red-50" role="alert">
                            The login details are incorrect
                        </div>
                    }
                    <form onSubmit={handleSubmit} className="mt-8 w-[487px]">
                        <div className="mb-6">
                            <label htmlFor="email"
                                   className="block mb-2 text-sm font-medium text-gray-900">EMAIL</label>
                            <input type="email" id="email" name="username" value={form.username} onChange={handleChange}
                                   className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-300 block w-full p-2.5"
                                   required/>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="password"
                                   className="block mb-2 text-sm font-medium text-gray-900">MẬT KHẨU</label>
                            <div className="flex border border-gray-300 rounded-lg">
                                <input type={type} id="password" name="password" value={form.password} onChange={handleChange}
                                       className="text-gray-900 text-sm rounded-lg focus:ring-0 border-0 block w-full p-2.5"
                                       required/>
                                <button type="button" onClick={showPass} className="px-2.5">
                                    {!show &&
                                        <FontAwesomeIcon icon="fa-regular fa-eye-slash" />
                                    }
                                    {show &&
                                        <FontAwesomeIcon icon="fa-regular fa-eye" />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className="mb-4 text-end">
                            <Link to="/" className="hover:underline">QUÊN MẬT KHẨU?</Link>
                        </div>
                        <button disabled={loading}
                            className="w-full px-5 py-3 rounded-lg bg-[#85BDC2] text-white text-lg font-bold hover:opacity-85 disabled:bg-gray-300">Đăng nhập
                        </button>
                    </form>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Login;