import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {addressService} from "../../services/addressService";

function Payment() {
    const {cartList} = useSelector(state => state.cart);
    const [address, setAddress] = useState({
        provinces: [],
        districts: [],
        wards: []
    });
    const [currentAddress, setCurrentAddress] = useState({
        province: 0,
        district: 0
    })

    useEffect(() => {
        getProvinces().then();
    }, [])

    const getProvinces = async () => {
        try {
            const res = await addressService.listProvinces();
            setAddress({provinces: res.data});
        } catch (e) {
            console.log(e);
        }
    }

    const chooseProvince = async (e) => {
        try {
            const {value} = e.target;
            setCurrentAddress({province: value});

            if (value !== 0) {
                const res = await addressService.getProvince(value);
                setAddress({...address, districts: res.data.districts});
            }
        } catch (e) {
            console.log(e);
        }
    }

    const chooseDistrict = async (e) => {
        try {
            const {value} = e.target;
            setCurrentAddress({district: value});

            if (value !== 0) {
                const res = await addressService.getDistrict(value);
                setAddress({...address, wards: res.data.wards});
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Header/>
            <section className="container mx-auto my-16">
                <h1 className="text-5xl font-black mb-14">THANH TOÁN</h1>
                <form className="grid grid-cols-12">
                    <div className="col-span-6">
                        <div className="flex flex-col gap-4">
                            <h4 className="font-medium">Thông tin giao hàng</h4>
                            <p>Bạn đã có tài khoản? <Link to="/auth/login" className="text-[#FF884A] hover:underline">Đăng
                                nhập</Link></p>
                            <input type="text"
                                   className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3"
                                   placeholder="Họ và tên" required/>
                            <div className="grid grid-cols-2 gap-4">
                                <input type="email"
                                       className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3"
                                       placeholder="Email"/>
                                <input type="tel"
                                       className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3"
                                       placeholder="Số điện thoại" required/>
                            </div>
                            <input type="text"
                                   className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3"
                                   placeholder="Địa chỉ" required/>
                            <div className="grid grid-cols-3 gap-4">
                                <select id="province" onChange={chooseProvince} value={currentAddress.province}
                                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3">
                                    <option selected value={0}>Chọn tỉnh / thành</option>
                                    {address.provinces?.map((item, index) =>
                                        <option key={index} value={item.code}>{item.name}</option>
                                    )}
                                </select>
                                <select id="district" onChange={chooseDistrict} value={currentAddress.district}
                                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3">
                                    <option selected value={0}>Chọn quận / huyện</option>
                                    {address.districts?.map((item, index) =>
                                        <option key={index} value={item.code}>{item.name}</option>
                                    )}
                                </select>
                                <select id="commune"
                                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3">
                                    <option selected>Chọn phường / xã</option>
                                    {address.districts?.map((item, index) =>
                                        <option key={index} value={item.code}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                            <select id="payment-method"
                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3">
                                <option selected>Chọn phương thức thanh toán</option>
                                <option value="US">United States</option>
                                <option value="CA">Canada</option>
                                <option value="FR">France</option>
                                <option value="DE">Germany</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-span-1"></div>
                    <div className="col-span-5">
                        <ul className="divide-y divide-gray-200">
                            {cartList?.map((product, index) =>
                                <li key={index} className="py-5">
                                    <div className="flex items-center gap-8">
                                        <div className="flex-shrink-0">
                                            <img className="w-20 h-20 rounded-lg aspect-square object-cover"
                                                 src={product?.path} alt=""/>
                                        </div>
                                        <div className="flex-1 inline-flex flex-col gap-3">
                                            <p className="text-sm">{product?.productName}</p>
                                            <em className="text-xs text-gray-500">2-3y</em>
                                        </div>
                                        <div className="flex flex-col items-end justify-between">
                                            <span
                                                className="text-sm text-[#FF884A]">{(product?.price * product?.count).toFixed(2)} VND</span>
                                        </div>
                                    </div>
                                </li>
                            )}

                            <li className="py-5">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-4">
                                        <input type="tel"
                                               className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-3"
                                               placeholder="Mã giảm giá"/>
                                    </div>
                                    <button
                                        className="col-span-2 p-2 rounded-lg bg-[#85BDC2] text-white text-center text-lg font-semibold hover:opacity-85">Sử
                                        dụng
                                    </button>
                                </div>
                            </li>
                            <li className="py-5">
                                <div className="grid grid-cols-6 items-center gap-4">
                                    <span className="col-span-4">
                                        Khách hàng thân thiết
                                    </span>
                                    <button
                                        className="col-span-2 p-2 rounded-lg bg-[#FF884A] text-white text-center text-lg font-semibold hover:opacity-85">Đăng
                                        nhập
                                    </button>
                                </div>
                            </li>
                            <li className="py-5">
                                <div className="flex items-center justify-between">
                                    <span className="text-sm font-thin">Tạm tính</span>
                                    <span
                                        className="text-sm font-light text-[#FF884A]">{cartList.reduce((total, product) => total + product.price, 0)} VND</span>
                                </div>
                                <div className="flex items-center justify-between mt-2">
                                    <span className="text-sm font-thin">Phí vận chuyển</span>
                                    <span className="text-sm font-light text-[#FF884A]"></span>
                                </div>
                            </li>
                            <li className="py-5">
                                <div className="flex items-center justify-between">
                                    <span className="text-lg">Tổng cộng</span>
                                    <span
                                        className="text-lg text-[#FF884A]">{cartList.reduce((total, product) => total + product.price, 0)} VND</span>
                                </div>
                            </li>
                        </ul>

                        <button type="submit"
                                className="w-full mt-10 px-5 py-2.5 rounded-lg bg-[#FF884A] text-white text-center text-lg font-bold hover:opacity-85">Thanh
                            toán
                        </button>
                    </div>
                </form>
            </section>
            <Footer/>
        </>
    );
}

export default Payment;