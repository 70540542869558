import store from "../stores";
import {setCurrentUser} from "../stores/user";
import axiosClient from "../utils/interceptor";

export const authService = {
    login: (form) => {
        return axiosClient.post("/api/Customer/login", form);
    },

    registration(user) {
        return axiosClient.post("/api/Customer/Register", user).then(res => {
            if (res.status === 200 || res.status === 201) {
                authRedirect();
            }
        });
    },
}

// function setAuthFromLocalStorage(auth) {
//     // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
//     if (auth && auth.accessToken) {
//         let now = new Date();
//
//         const item = {
//             accessToken: auth.accessToken,
//             tokenType: auth.tokenType,
//             expiry: now.getTime() + (1000 * 60 * 60 * 24 * 10)
//         }
//         localStorage.setItem(Constant.TOKEN_KEY, JSON.stringify(item));
//         return true;
//     }
//     return false;
// }
//
// function getAuthFromLocalStorage() {
//     try {
//         const lsValue = localStorage.getItem(Constant.TOKEN_KEY);
//         if (!lsValue) {
//             return undefined;
//         }
//
//         const authData = JSON.parse(lsValue);
//         let now = new Date()
//         // compare the expiry time of the item with the current time
//         if (now.getTime() > authData.expiry) {
//             localStorage.removeItem(Constant.TOKEN_KEY)
//             return undefined;
//         }
//
//         return authData;
//     } catch (error) {
//         console.error(error);
//         return undefined;
//     }
// }

function authRedirect(logout = false) {
    const redirect = sessionStorage.getItem('register-artist');
    window.location.href = redirect ? (logout ? '/auth/login' : redirect) : '/';
}
