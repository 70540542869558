import {createBrowserRouter, createRoutesFromElements, Navigate, Route} from "react-router-dom";
import App from "../App";
import Home from "../pages/home/home";
import PageNotFound from "../pages/404/page-not-found";
import FlashSale from "../pages/flash-sale/flash-sale";
import ProductList from "../pages/product/list";
import ProductDetails from "../pages/product/details";
import HandBook from "../pages/handbook/list";
import HandBookDetails from "../pages/handbook/details";
import Contact from "../pages/contact/contact";
import AIChoice from "../pages/AI-choice/AI-choice";
import AIChoiceImplement from "../pages/AI-choice/AI-choice-implement";
import ShoppingCart from "../pages/shopping/cart";
import Payment from "../pages/shopping/payment";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import Logout from "../pages/auth/logout";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<App />}>
            <Route index path="/" element={<Home />} />
            <Route path="/tat-ca-san-pham" element={<ProductList />} />
            <Route path="/san-pham/:url" element={<ProductDetails />} />
            <Route path="/cam-nang" element={<HandBook />} />
            <Route path="/cam-nang/:url" element={<HandBookDetails />} />
            <Route path="/lien-he" element={<Contact />} />
            <Route path="/ai-choice" element={<AIChoice />} />
            <Route path="/ai-choice-implement/" element={<AIChoiceImplement />} />
            <Route path="/flash-sale" element={<FlashSale />} />
            <Route path="/gio-hang" element={<ShoppingCart />} />
            <Route path="/thanh-toan" element={<Payment />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/register" element={<Register />} />
            <Route path="/auth/logout" element={<Logout />} />
            <Route path="/not-found" element={<PageNotFound /> } />
            <Route path="/*" element={<Navigate to="/not-found" replace={true} />} />
        </Route>
    )
);