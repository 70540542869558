// src/pages/auth/logout.js
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser } from '../../stores/user';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Clear user state in Redux
    dispatch(setCurrentUser(null));

    // Optionally clear localStorage/sessionStorage if you are using it
    // localStorage.removeItem('user');

    // Redirect to login page or home page
    navigate('/auth/login');
  }, [dispatch, navigate]);

  return null; // You don't need to render anything
};

export default Logout;
