import { useSelector } from "react-redux";
import "flowbite";
import { Link, useLocation } from "react-router-dom";
import logo from "assets/logo/logo.png";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header() {
  const { cartList } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const [activeRoute, setActiveRoute] = useState("home");

  const match = useLocation();
  useEffect(() => {
    setActiveRoute(match.pathname);
  }, [match]);

  return (
    <header className="bg-white drop-shadow-md sticky -top-16 z-50">
      <div className="h-16 grid grid-cols-2 items-center px-10">
        <div className="flex items-center gap-2.5">
          <img src={logo} className="object-fill w-11 h-11" alt="" />
          <h2 className="font-black text-xl text-[#FF884A]">
            Hicon - Newborn & BabyCare
          </h2>
        </div>
        <div className="flex items-center justify-between gap-2.5">
          <form className="flex items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <input
              type="text"
              id="simple-search"
              className="border border-gray-300 text-gray-900 rounded-lg focus:ring-[#FF884A] focus:border-[#FF884A] block w-[300px] px-3 py-2"
              placeholder="Tìm kiếm sản phẩm..."
              required
            />
            <button
              type="submit"
              className="p-2.5 ms-2 text-sm font-medium rounded-lg focus:ring-4 focus:outline-none focus:ring-[#FF884A60]"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </form>
          {user ? (
            <div className="flex items-center gap-2.5">
              <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                <svg
                  className="absolute w-12 h-12 text-gray-400 -left-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <Link
                to="/auth/logout"
                className="font-black text-lg text-[#FF884A]"
              >
                Đăng xuất
              </Link>

          </div>
          ) : (
            <div className="flex items-center gap-2.5">
              <Link
                to="/auth/login"
                className="font-black text-lg text-[#FF884A]"
              >
                Đăng nhập
              </Link>
              <Link
                to="/auth/register"
                className="font-black text-lg text-[#FF884A] before:border-l-2 before:ps-2.5 before:border-[#FF884A]"
              >
                Đăng ký
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="bg-[#DCF7F9] grid grid-cols-12 h-20">
        <div className="col-span-10 md:col-span-4">
          <div className="flex items-center justify-between gap-4 h-full bg-[#85BDC2]">
            <div className="px-12">
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                data-dropdown-trigger="click"
                className="font-medium inline-flex items-center gap-4"
              >
                <svg
                  className="w-6 h-6 text-gray-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M5 7h14M5 12h14M5 17h14"
                  />
                </svg>
                <span>Danh mục sản phẩm</span>
              </button>

              <div
                id="dropdown"
                className="z-10 hidden bg-white rounded shadow w-96"
              >
                <ul className="py-2 text-sm text-gray-700 divide-y divide-gray-200 text-start">
                  <li>
                    <a href="/" className="block px-4 py-2 hover:bg-gray-100">
                      Áo
                    </a>
                  </li>
                  <li>
                    <a href="/" className="block px-4 py-2 hover:bg-gray-100">
                      Quần
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-2 md:hidden">
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        <div className="col-span-8">
          <div
            className="hidden w-full md:flex h-full px-4"
            id="navbar-default"
          >
            <ul className="font-medium w-full flex items-center justify-between flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row rtl:space-x-reverse md:mt-0 md:border-0">
              <li
                className={
                  "px-6 hover:text-[#FF884A]" +
                  (activeRoute === "/" ? " text-[#FF884A]" : "")
                }
              >
                <Link to="/">Trang chủ</Link>
              </li>
              <li
                className={
                  "px-6 hover:text-[#FF884A]" +
                  (activeRoute === "/tat-ca-san-pham" ? " text-[#FF884A]" : "")
                }
              >
                <Link to="/tat-ca-san-pham">Tất cả sản phẩm</Link>
              </li>
              <li
                className={
                  "px-6 hover:text-[#FF884A]" +
                  (activeRoute === "/cam-nang" ? " text-[#FF884A]" : "")
                }
              >
                <Link to="/cam-nang">Cẩm nang</Link>
              </li>
              <li
                className={
                  "px-6 hover:text-[#FF884A]" +
                  (activeRoute === "/ai-choice" ? " text-[#FF884A]" : "")
                }
              >
                <Link to="/ai-choice">AI Choice</Link>
              </li>
              <li
                className={
                  "px-6 hover:text-[#FF884A]" +
                  (activeRoute === "/lien-he" ? " text-[#FF884A]" : "")
                }
              >
                <Link to="/lien-he">Liên hệ</Link>
              </li>
              <li
                className={
                  "px-6 hover:text-[#FF884A]" +
                  (activeRoute === "/flash-sale" ? " text-[#FF884A]" : "")
                }
              >
                <Link to="/flash-sale">FLASH SALE</Link>
              </li>
              <li className="px-6">
                <Link
                  to="/gio-hang"
                  className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-black bg-white rounded-3xl hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300"
                >
                  <FontAwesomeIcon icon="fa-solid fa-cart-shopping" />
                  <div className="inline-flex items-center justify-center w-5 h-5 ms-2 text-xs font-semibold text-white bg-[#FF884A] rounded-full">
                    {cartList.length}
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
