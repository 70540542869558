import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {decreaseCart, increaseCart, removeFromCart} from "../../stores/cartSlide";
import {useEffect, useState} from "react";

function ShoppingCart() {
    const dispatch = useDispatch();
    const {cartList} = useSelector(state => state.cart);
    const [cart, setCart] = useState({
        count: 0,
        total: 0,
    });

    useEffect(() => {
        const quantity = cartList.reduce((total, product) => total + product.count, 0)
        const totalPrice = cartList.reduce((total, product) => total + product.price, 0)
        setCart({count: quantity, total: totalPrice});
    }, [cartList])

    const increase = (id) => {
        dispatch(increaseCart(id));
    }

    const decrease = (product) => {
        if (product.count > 1) {
            dispatch(decreaseCart(product.id));
        }
    }

    const clearItem = (id) => {
        dispatch(removeFromCart(id));
    }

    return (
        <>
            <Header/>
            <section className="container mx-auto my-16">
                <h1 className="text-5xl font-black mb-14">GIỎ HÀNG</h1>
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-6">
                        <ul className="divide-y divide-gray-200">
                            <li className="pb-3">
                                <div className="flex items-center justify-between">
                                    <span className="flex-shrink-0">SẢN PHẨM</span>
                                    <span className="text-base font-semibold text-gray-900">TỔNG</span>
                                </div>
                            </li>
                            {cartList?.map((product, index) => (
                                <li key={index} className="py-3 sm:py-4">
                                    <div className="flex items-center gap-5">
                                        <div className="flex-shrink-0">
                                            <img className="w-40 h-40 rounded-lg aspect-square object-cover"
                                                 src={product?.path} alt=""/>
                                        </div>
                                        <div className="flex-1 inline-flex flex-col gap-2.5">
                                            <p className="text-lg">{product?.productName}</p>
                                            <span className="text-base font-medium text-[#FF884A]">{product?.price} VND</span>
                                            <em className="text-xs text-gray-500">2-3y</em>
                                            <div>
                                                <p className="text-base text-gray-500 truncate"></p>
                                            </div>
                                            <div className="relative flex items-center max-w-[8rem] mt-4">
                                                <button type="button" onClick={() => decrease(product)}
                                                        className="bg-[#DCF7F9] rounded-s-md p-1.5 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                    <FontAwesomeIcon icon="fa-solid fa-minus"/>
                                                </button>
                                                <span
                                                    className="bg-[#DCF7F9] border-0 text-center font-semibold focus:ring-blue-500 focus:ring-0 block w-full py-1.5">
                                                    {product?.count}
                                                </span>
                                                <button type="button" onClick={() => increase(product?.id)}
                                                        className="bg-[#DCF7F9] rounded-e-lg p-1.5 focus:ring-gray-100 focus:ring-2 focus:outline-none">
                                                    <FontAwesomeIcon icon="fa-solid fa-plus"/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end justify-between">
                                            <span className="text-lg text-[#FF884A]">{(product?.price * product?.count).toFixed(2)} VND</span>
                                            <button className="text-gray-500 underline" onClick={() => clearItem(product?.id)}>Xóa</button>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="message"
                                               className="block mb-2 text-base font-medium text-gray-900">Ghi
                                            chú</label>
                                        <textarea id="message" rows="3"
                                                  className="block p-2.5 w-full text-sm text-gray-900 bg-[#DCF7F966] rounded-lg border-0"
                                                  placeholder="Vui lòng nhập ghi chú của bạn ..."></textarea>

                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-span-2"></div>
                    <div className="col-span-4">
                        <ul className="divide-y divide-gray-200">
                            <li className="pb-3">
                                <div className="flex items-center justify-between">
                                    <span className="flex-shrink-0">THÔNG TIN ĐƠN HÀNG</span>
                                </div>
                            </li>
                            <li className="py-3 sm:py-4">
                                <div className="flex flex-col gap-2.5">
                                    <div className="flex justify-between items-center">
                                        <span className="text-base">Tạm tính ({cart.count} sản phẩm)</span>
                                        <span>{cart.total} VND</span>
                                    </div>
                                    <p className="text-sm font-thin">Phí vận chuyển sẽ được tính ở trang thanh toán. Bạn
                                        cũng có thể nhập mã giảm giá ở trang thanh toán.</p>
                                </div>
                                <div className="flex flex-col gap-2.5 mt-24">
                                    <div className="flex flex-col gap-6">
                                        <div className="flex justify-between items-center">
                                            <span className="text-base">Tổng cộng</span>
                                            <span className="text-[#FF884A]">{cart.total} VND</span>
                                        </div>
                                        <Link to="/thanh-toan"
                                              className="px-5 py-2 rounded-lg bg-[#FF884A] text-white text-center text-lg font-bold hover:opacity-85">Đặt
                                            hàng
                                        </Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default ShoppingCart;