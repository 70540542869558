import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import img18 from "../../assets/images/img18.png";
import img19 from "../../assets/images/img19.png";
import {useEffect, useState} from "react";
import {categoryService} from "../../services/categoryService";
import {Link} from "react-router-dom";

function HandBook() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const getAll = async () => {
            try {
                const res = await categoryService.getAllCategories();
                setCategories(res.data);
            } catch (e) {
                console.log(e);
            }
        }

        getAll().then();
    }, [])
    return (
        <>
            <Header/>
            <section className="container mx-auto py-8">
                <div className="grid grid-cols-12 gap-11">
                    <div className="col-span-4">
                        <ul>
                            {categories?.map((item, index) =>
                                <li key={index} className="pb-3 mb-3 border-b">
                                    <Link to={`/danh-muc/${item?.slug}`} className="hover:underline">{item?.categoryName}</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="col-span-8">
                        <div className="flex flex-col gap-10">
                            <h1 className="text-6xl text-[#FF884A] font-black text-center">Cẩm nang</h1>
                            <div className="grid grid-cols-2 gap-16">
                                <div className="relative">
                                    <img src={img18} className="rounded-lg aspect-square object-cover" alt=""/>
                                    <button
                                        className="absolute top-0 left-0 bg-[#2C2C2C] w-9 h-9 text-white rounded-full">
                                        <FontAwesomeIcon icon="fa-regular fa-heart"/></button>
                                </div>

                                <div className="flex flex-col gap-4">
                                    <Link to="/cam-nang/1" className="text-3xl font-[600] uppercase">5 LƯU Ý VÀNG KHI CHỌN CHẤT LIỆU VẢI
                                        CHO BÉ YÊU</Link>
                                    <span
                                        className="bg-[#FF884A] text-sm text-white w-fit px-3 py-1 rounded-xl">Cẩm nang Mẹ và Bé</span>
                                    <p>Khi trẻ chào đời, tiếng khóc đầu tiên sẽ đánh dấu khả năng sống độc lập của trẻ.
                                        Trong những năm tháng đầu đời, sức đề kháng cũng như hệ miễn dịch của trẻ sơ
                                        sinh còn non nớt, chưa đủ sức chống trả với các tác nhân gây bệnh. </p>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-16">
                                <div className="relative">
                                    <img src={img19} className="rounded-lg aspect-square object-cover" alt=""/>
                                    <button
                                        className="absolute top-0 left-0 bg-[#2C2C2C] w-9 h-9 text-white rounded-full">
                                        <FontAwesomeIcon icon="fa-regular fa-heart"/></button>
                                </div>

                                <div className="flex flex-col gap-4">
                                    <Link to="/cam-nang/2" className="text-3xl font-[600] uppercase">ĐIỂM DANH 5 OUTFIT CHO BÉ
                                        MÙA THU ĐÔNG</Link>
                                    <span
                                        className="bg-[#FF884A] text-sm text-white w-fit px-3 py-1 rounded-xl">Cẩm nang Mẹ và Bé</span>
                                    <p>Khi trẻ chào đời, tiếng khóc đầu tiên sẽ đánh dấu khả năng sống độc lập của trẻ.
                                        Trong những năm tháng đầu đời, sức đề kháng cũng như hệ miễn dịch của trẻ sơ
                                        sinh còn non nớt, chưa đủ sức chống trả với các tác nhân gây bệnh. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default HandBook;