// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* In your CSS file (e.g., styles.css) or within a <style> tag in your component */

.Modal {
  position: absolute;
  top: 30%;
  left: 0%;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 90%;
  max-height: 60%;
  overflow-y: scroll;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  z-index: 1000; /* Ensure the modal is above other elements */
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  z-index: 999; /* Ensure the overlay covers the background */
}

ul.slider {
  transform: translate3d(0, 0, 0) !important; /* Set to 0,0 to ensure it's fully visible */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;;AAGA,kFAAkF;;AAElF;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,qBAAqB,EAAE,8BAA8B;EACrD,aAAa,EAAE,6CAA6C;AAC9D;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC,EAAE,oBAAoB;EAC1D,YAAY,EAAE,6CAA6C;AAC7D;;AAEA;EACE,0CAA0C,EAAE,4CAA4C;AAC1F","sourcesContent":["\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n\n/* In your CSS file (e.g., styles.css) or within a <style> tag in your component */\n\n.Modal {\n  position: absolute;\n  top: 30%;\n  left: 0%;\n  background-color: white;\n  border-radius: 8px;\n  padding: 20px;\n  max-width: 90%;\n  max-height: 60%;\n  overflow-y: scroll;\n  scrollbar-width: none; /* Hide scrollbar in Firefox */\n  z-index: 1000; /* Ensure the modal is above other elements */\n}\n\n.Overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.7); /* Dark background */\n  z-index: 999; /* Ensure the overlay covers the background */\n}\n\nul.slider {\n  transform: translate3d(0, 0, 0) !important; /* Set to 0,0 to ensure it's fully visible */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
