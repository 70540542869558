import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import img3 from "../../assets/images/img3.png";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {productService} from "../../services/productService";
import {useDispatch, useSelector} from "react-redux";
import {addToCart} from "../../stores/cartSlide";
import {Carousel} from "flowbite-react";

function ProductDetails() {
    const {url} = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState({});

    const dispatch = useDispatch();
    const {cartList} = useSelector(state => state.cart);

    useEffect(() => {
        const getProduct = async () => {
            try {
                const res = await productService.getProductById(url);
                setProduct(res.data);
                console.log(res.data);
            } catch (e) {
                console.log(e);
            }
        }

        getProduct().then();
    }, [url])

    const addProduct = () => {
        if (cartList?.findIndex(item => item.id === product.productId) !== -1) {
            navigate('/gio-hang');
        } else {
            const prod = {
                id: product.productId,
                productName: product.productName,
                productDescription: product.productDescription,
                path: product.assets[0].path,
                discount: product.discount,
                price: product.price,
                count: 1
            }

            dispatch(addToCart(prod));
        }
    }

    return (
        <>
            <Header/>
            <section className="container mx-auto px-4 py-9">
                <div className="grid grid-cols-9 gap-16">
                    <div className="col-span-5">
                        <div className="relative w-full aspect-[5/4]">
                            <Carousel slide={false}>
                                {product?.assets?.map((item, index) =>
                                    <img key={index} src={item.path}
                                         className="w-full rounded-lg aspect-square object-cover" alt="..."/>
                                )}
                            </Carousel>
                        </div>

                        {/*<img src={product?.assets} className="w-full rounded-lg aspect-square object-cover" alt=""/>*/}
                    </div>
                    <div className="col-span-4">
                        <div className="flex flex-col gap-6">
                            <h2 className="text-4xl font-[600]">{product?.productName}</h2>
                            <span className="text-2xl text-[#FF884A] font-black">Giá: {product?.price?.toLocaleString('vi-VN')} VND</span>
                            <span dangerouslySetInnerHTML={{ __html: product.productDescription }} />
                            <button onClick={addProduct}
                                    className="px-5 py-2.5 rounded-lg bg-[#85BDC2] font-bold text-2xl text-white hover:opacity-85">Thêm
                                vào giỏ hàng
                            </button>
                            <button
                                className="px-5 py-2.5 rounded-lg bg-[#FF884A] font-bold text-2xl text-white hover:opacity-85">Thử
                                đồ với AI
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-6 mt-12">
                    <h2 className="text-2xl font-bold">Sản phẩm liên quan</h2>
                    <div className="grid grid-cols-3 gap-16">
                        <div className="relative flex flex-col gap-6 rounded-lg">
                            <img src={img3} className="rounded-2xl aspect-[16/10] object-cover" alt=""/>
                            <div className="flex flex-col items-center gap-2">
                                <a href="/san-pham/details" className="text-xl font-black text-center truncate">Combo
                                    quần cho bé</a>
                                <span className="text-[#828282] italic">Thương hiệu: Việt Nam</span>
                                <span className="text-xl font-black text-[#FF884A]">200.000 VND</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default ProductDetails;