import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import banner1 from "../../assets/banner/banner1.png";
import img9 from "../../assets/images/img9.png";
import img10 from "../../assets/images/img10.png";
import img11 from "../../assets/images/img11.png";
import img12 from "../../assets/images/img12.png";
import img13 from "../../assets/images/img13.png";
import img14 from "../../assets/images/img14.png";

function FlashSale() {
    return (
        <>
            <Header/>
            <img src={banner1} alt=""/>
            <section className="container mx-auto py-8">
                <div className="grid grid-cols-3 gap-8">
                    <div className="relative flex flex-col gap-6 rounded-lg">
                        <img src={img9} className="rounded-lg aspect-[16/10] object-cover" alt=""/>
                        <div className="flex flex-col items-center gap-3 px-5">
                            <h3 className="text-xl font-black text-center">Bộ cài thẳng dài áo trắng họa tiết gấu xanh
                                Hicon Việt Nam chính hãng</h3>
                            <div className="inline-flex items-center gap-4">
                                <span className="text-xl font-bold text-[#D9D9D9]">250.000 VND</span>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="text-[#FF884A]"/>
                                <span className="text-xl font-bold text-[#FF884A]">227.000 VND</span>
                            </div>
                        </div>
                        <span
                            className="absolute top-0 h-12 w-48 bg-[#FF884A] text-white font-bold text-xl inline-flex items-center justify-center rounded-tl-lg">Giảm 23.000</span>
                    </div>
                    <div className="relative flex flex-col gap-6 rounded-lg">
                        <img src={img10} className="rounded-lg aspect-[16/10] object-cover" alt=""/>
                        <div className="flex flex-col items-center gap-3 px-5">
                            <h3 className="text-xl font-black text-center">Áo rời sơ sinh cộc tay</h3>
                            <div className="inline-flex items-center gap-4">
                                <span className="text-xl font-bold text-[#D9D9D9]">230.000 VND</span>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="text-[#FF884A]"/>
                                <span className="text-xl font-bold text-[#FF884A]">196.000 VND</span>
                            </div>
                        </div>
                        <span
                            className="absolute top-0 h-12 w-48 bg-[#FF884A] text-white font-bold text-xl inline-flex items-center justify-center rounded-tl-lg">Giảm 36.000</span>
                    </div>
                    <div className="relative flex flex-col gap-6 rounded-lg">
                        <img src={img11} className="rounded-lg aspect-[16/10] object-cover" alt=""/>
                        <div className="flex flex-col items-center gap-3 px-5">
                            <h3 className="text-xl font-black text-center">Bộ cài thẳng dài áo trắng họa tiết gấu xanh
                                Hicon Việt Nam chính hãng</h3>
                            <div className="inline-flex items-center gap-4">
                                <span className="text-xl font-bold text-[#D9D9D9]">195.000 VND</span>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="text-[#FF884A]"/>
                                <span className="text-xl font-bold text-[#FF884A]">170.000 VND</span>
                            </div>
                        </div>
                        <span
                            className="absolute top-0 h-12 w-48 bg-[#FF884A] text-white font-bold text-xl inline-flex items-center justify-center rounded-tl-lg">Giảm 25.000</span>
                    </div>
                    <div className="relative flex flex-col gap-6 rounded-lg">
                        <img src={img12} className="rounded-lg aspect-[16/10] object-cover" alt=""/>
                        <div className="flex flex-col items-center gap-3 px-5">
                            <h3 className="text-xl font-black text-center">Bộ cài thẳng dài áo trắng họa tiết gấu xanh
                                Hicon Việt Nam chính hãng</h3>
                            <div className="inline-flex items-center gap-4">
                                <span className="text-xl font-bold text-[#D9D9D9]">220.000 VND</span>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="text-[#FF884A]"/>
                                <span className="text-xl font-bold text-[#FF884A]">190.000 VND</span>
                            </div>
                        </div>
                        <span
                            className="absolute top-0 h-12 w-48 bg-[#FF884A] text-white font-bold text-xl inline-flex items-center justify-center rounded-tl-lg">Giảm 30.000</span>
                    </div>
                    <div className="relative flex flex-col gap-6 rounded-lg">
                        <img src={img13} className="rounded-lg aspect-[16/10] object-cover" alt=""/>
                        <div className="flex flex-col items-center gap-3 px-5">
                            <h3 className="text-xl font-black text-center">Bộ cài thẳng dài áo trắng họa tiết gấu xanh
                                Hicon Việt Nam chính hãng</h3>
                            <div className="inline-flex items-center gap-4">
                                <span className="text-xl font-bold text-[#D9D9D9]">200.000 VND</span>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="text-[#FF884A]"/>
                                <span className="text-xl font-bold text-[#FF884A]">117.000 VND</span>
                            </div>
                        </div>
                        <span
                            className="absolute top-0 h-12 w-48 bg-[#FF884A] text-white font-bold text-xl inline-flex items-center justify-center rounded-tl-lg">Giảm 23.000</span>
                    </div>
                    <div className="relative flex flex-col gap-6 rounded-lg">
                        <img src={img14} className="rounded-lg aspect-[16/10] object-cover" alt=""/>
                        <div className="flex flex-col items-center gap-3 px-5">
                            <h3 className="text-xl font-black text-center">Bộ cài thẳng dài áo trắng họa tiết gấu xanh
                                Hicon Việt Nam chính hãng</h3>
                            <div className="inline-flex items-center gap-4">
                                <span className="text-xl font-bold text-[#D9D9D9]">210.000 VND</span>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="text-[#FF884A]"/>
                                <span className="text-xl font-bold text-[#FF884A]">192.000 VND</span>
                            </div>
                        </div>
                        <span
                            className="absolute top-0 h-12 w-48 bg-[#FF884A] text-white font-bold text-xl inline-flex items-center justify-center rounded-tl-lg">Giảm 18.000</span>
                    </div>
                </div>

                <div className="flex justify-center mt-16">
                    <button className="px-5 py-2 rounded-lg bg-[#FF884A] text-white hover:opacity-85">Xem thêm</button>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default FlashSale;