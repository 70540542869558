import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {useEffect, useState} from "react";
import {productService} from "../../services/productService";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addToCart} from "../../stores/cartSlide";

function ProductList() {
    const dispatch = useDispatch();
    const {cartList} = useSelector(state => state.cart);
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        const getAll = async () => {
            try {
                const res = await productService.getNumberProducts(6);
                console.log("res" + res.data);
                console.log("res" + res);
                setProducts(res.data);
            } catch (e) {
                console.log(e);
            }
        }
        getAll().then();
    }, [])

    const addProduct = (product) => {
        if (cartList?.findIndex(item => item.id === product.productId) !== -1) {
            navigate('/gio-hang');
        } else {
            const prod = {
                id: product.productId,
                productName: product.productName,
                productDescription: product.productDescription,
                path: product.path,
                discount: product.discount,
                price: product.price,
                count: 1
            }
            dispatch(addToCart(prod));
        }
    }

    const getAllProducts = async () => {
        try {
            const res = await productService.getAllProducts();
            setProducts(res.data);
            console.log(res.data);
            setShowAll(true);
        } catch (e) {
            console.log(e);
            setShowAll(false);
        }
    }

    return (
        <>
            <Header/>
            <section className="container mx-auto py-8">
                <h1 className="text-6xl text-[#FF884A] font-black my-16 text-center">Tất cả sản phẩm</h1>

                <div className="grid grid-cols-3 gap-10">
                    {products.map((product, index) => (
                        <div key={index} className="flex flex-col gap-6 rounded-lg">
                            <img loading="lazy" src={product?.path} className="rounded-lg aspect-[16/10] object-cover" alt=""/>
                            <div className="relative flex flex-col items-center gap-3">
                                <Link to={`/san-pham/${product?.productId}`}
                                   className="w-full text-xl font-black text-center truncate ...">{product?.productName}</Link>
                                <span className="text-xl font-black text-[#FF884A]">{product?.price?.toLocaleString('vi-VN')} VND</span>
                                <button onClick={() => addProduct(product)}
                                    className="absolute bottom-0 right-0 focus:ring-4 rounded focus:outline-none focus:ring-[#dcf7f9]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {!showAll &&
                    <div className="flex justify-center mt-16">
                        <button onClick={getAllProducts}
                                className="px-5 py-2 rounded-lg bg-[#FF884A] text-white hover:opacity-85">Xem thêm
                        </button>
                    </div>
                }
            </section>
            <Footer/>
        </>
    )
}

export default ProductList;