import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import cartReducer from "./cartSlide";
import modalRedux from "./modal";
import { combineReducers } from 'redux';
import userRedux from "./user";
import { persistStore, persistReducer } from 'redux-persist';


const persistConfig = {
  key: 'root',  // Key for the persisted state in localStorage
  storage,      // Use localStorage to persist state
};

// Combine all reducers into a rootReducer
const rootReducer = combineReducers({
  cart: cartReducer,
  modal: modalRedux,
  user: userRedux,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const stores = configureStore({
  reducer: persistedReducer,
});

// Create a persistor for persisting the store
export const persistor = persistStore(stores);


export default stores;