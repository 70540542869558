import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from 'assets/logo/logo.png';

function Footer() {
    return (
        <footer className="bg-[#DCF7F9] mt-12">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between">
                    <div className="flex flex-col items-start gap-4">
                        <div className="flex flex-col gap-3 items-center">
                            <span className="text-2xl font-black">Hicon</span>
                            <img src={logo} className="object-fill w-16 h-16" alt="Logo"/>
                        </div>

                        <div className="flex items-center gap-4">
                            <a href="https://www.facebook.com/profile.php?id=61561046967135" className="text-2xl text-gray-500 hover:text-gray-900" title="Facebook page">
                                <FontAwesomeIcon icon="fa-brands fa-facebook"/>
                            </a>
                            <a href="https://linkedin.com" className="text-2xl text-gray-500 hover:text-gray-900">
                                <FontAwesomeIcon icon="fa-brands fa-linkedin" />
                            </a>
                            <a href="https://youtube.com" className="text-2xl text-gray-500 hover:text-gray-900">
                                <FontAwesomeIcon icon="fa-brands fa-youtube" />
                            </a>
                            <a href="https://instagram.com" className="text-2xl text-gray-500 hover:text-gray-900">
                                <FontAwesomeIcon icon="fa-brands fa-instagram" />
                            </a>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-8 sm:grid-cols-3">
                        <div className="md:max-w-44">
                            <h2 className="mb-6 font-bold text-gray-900">Giới thiệu</h2>
                            <ul className="text-gray-500">
                                <li className="mb-4">
                                    <a href="/lien-he" className="hover:underline">Về chúng tôi</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/" className="hover:underline">Chính sách bảo mật</a>
                                </li>
                                <li>
                                    <a href="/" className="hover:underline">Điều khoản chung</a>
                                </li>
                            </ul>
                        </div>
                        <div className="md:max-w-44">
                            <h2 className="mb-6 font-bold text-gray-900">Hỗ trợ khách hàng</h2>
                            <ul className="text-gray-500">
                                <li className="mb-4">
                                    <a href="/" className="hover:underline">Chính sách đổi trả</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/" className="hover:underline">Quy định & hình thức thanh toán</a>
                                </li>
                                <li>
                                    <a href="/" className="hover:underline">Bảo hành & bảo trì</a>
                                </li>
                            </ul>
                        </div>
                        <div className="md:max-w-44">
                            <h2 className="mb-6 font-bold text-gray-900">Kết nối với chúng tôi</h2>
                            <ul className="text-gray-500">
                                <li className="mb-4">
                                    <a href="https://www.facebook.com/profile.php?id=61561046967135" className="hover:underline">Facebook</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/" className="hover:underline">Shopee</a>
                                </li>
                                <li>
                                    <a href="/" className="hover:underline">Tiktok</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;